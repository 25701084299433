import styled from "styled-components"
import { Container, MediaContainer, Media } from "../../hero"
import {H1} from "../../Text";
import {breakpoints} from "../../Style";

export const StyledHero = styled(Container)`
  margin: 0;
  margin-bottom: 3rem;
`

export const StyledMediaContainer = styled(MediaContainer)`
  background-color: inherit;
  height: inherit !important;
`

export const Cards = styled.div`
  margin: auto;
  position: relative;
`

export const Title = styled(H1)`
  font-size: 3rem;
  line-height: 3.3rem;
  font-weight: 900;
`

export const Preamble = styled.p`
  margin-top: 1.4rem;
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 2.2rem;
  font-size: 1.8rem;
`

export const TextContainer = styled.div`  
  @media ${breakpoints.small} {
    margin: 1rem 4rem;
  }
  
  @media ${breakpoints.medium} {
    margin: 3.5rem 7rem;
  }
  
  @media ${breakpoints.large} {
    margin: 7rem 14rem;
  }
`
