import styled from "styled-components"
import { Container, MediaContainer, Media } from "../../hero"
import {H1} from "../../Text";
import {breakpoints} from "../../Style";

export const StyledHero = styled(Container)`
  margin-bottom: 8.6rem;
`

export const StyledMediaContainer = styled(MediaContainer)`
  background-color: inherit;
  height: inherit;
  
  ${Container} & {
    @media ${breakpoints.small} {
      height: inherit;
    }
  
    @media ${breakpoints.medium} {
      height: inherit;
    }
  
    @media ${breakpoints.large} {
      height: inherit;
    }
  }
`

export const StyledMedia = styled(Media)`
  position: absolute !important;
  opacity: 0.4;
`

export const Logo = styled.img`
  position: relative;
  z-index: 1;
  width: 50vw;
  max-width: 84.4rem;
  margin: auto;
`

export const Cards = styled(Logo)`
  width: 60vw;
  max-width: inherit;
`

export const Title  = styled(H1)`
  font-size: 5rem;
  line-height: 5.4rem;
  font-weight: 900;
`

export const Preamble = styled.p`
  margin-top: 1.4rem;
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 2.6rem;

  @media ${breakpoints.small} and ${breakpoints.medium} {
    margin-bottom: 2rem;
    font-size: 2.2rem;
    max-width: 80vw;
  }
  
  @media ${breakpoints.large} {
    margin-bottom: 2rem;
    font-size: 2.2rem;
    max-width: 40vw;
  }
`

export const TextContainer = styled.div`  
  @media ${breakpoints.small} {
    margin: 1rem 4rem;
  }
  
  @media ${breakpoints.medium} {
    margin: 3.5rem 7rem;
  }
  
  @media ${breakpoints.large} {
    margin: 7rem 14rem;
  }
`
