import React, { useEffect } from "react"
import SEO from "../components/seo"
import useMobile from "../lib/useMobile"
import IntroDesktopHero from "../components/intro/desktop/"
import IntroMobileHero from "../components/intro/mobile/"
import Posts from "../components/posts/"
import Cases from "../components/cases/"
import { useStateValue } from "../state"
import Promotions from "../components/promo";

const IndexPage = ({ pageContext }) => {
  const [isMobile] = useMobile()
  const [state, dispatch] = useStateValue()

  useEffect(() => {
    if (!state.about.seen) {
      dispatch({ type: "ABOUT_OPEN" })
    }
  }, [isMobile, state.about.seen, dispatch])

  return (
    <>
      <SEO title="Hem" />
      {isMobile ? (
          <IntroMobileHero/>
        ) : (
          <IntroDesktopHero />
      )}
      <Posts
        title="Maskinoteket - vårt community"
        insert={true}
        models={pageContext.posts}
      />
      <Cases />
    </>
  )
}

export default IndexPage
