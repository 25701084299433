import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import {Content} from "../../hero"
import AddMachine from "../../form/"
import {StyledHero, StyledMediaContainer, StyledMedia, Logo, Cards, Title, Preamble, TextContainer} from "./Style"
import useMobile from "../../../lib/useMobile";

export default function IntroDesktopHero({ children }) {
  const [isMobile] = useMobile();

  const data = useStaticQuery(graphql`
    query {
      bgImg: file(relativePath: { eq: "bg-hero.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(relativePath: { eq: "TAV.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      cards: file(relativePath: { eq: "promo_cards.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <StyledHero>
      <StyledMediaContainer>
        <TextContainer>
          <Title>Dela din maskin bland <br/> andra som fattar <br/> känslan</Title>
          <Preamble>Det är inte alla som kör en Epiroc. Var stolt – och låt din maskin synas här tillsammans med andra som också gör skillnad på riktigt. Du lägger enkelt till den här intill.</Preamble>
        </TextContainer>
        <Cards as={Img} fluid={data.cards.childImageSharp.fluid} />
      </StyledMediaContainer>
      <Content>
        <AddMachine />
      </Content>
    </StyledHero>
  )
}
